
// Some global reset needed...
body {
  overflow-x: hidden;
}

// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header-top {
  padding: 1rem 1rem 0.5rem 1rem;
  background: #fff;
  font-size: 1.35rem;
  color: $colorAccent3;
  font-weight: 600;

  @media (min-width: $xl-break) {
    padding: 0 3.5rem 0 3.5rem;
  }

  .left-col {
    order: 2;
    text-align: right;

    .label-sutton {
      font-family: $fontText;
      font-size: 14px;
      font-weight: 400;
      span {
        font-family: $fontTitle;
        font-weight: 600;
        font-size: 20px;
      }
    }

    @media (min-width: $xl-break) {
      order: 0;
      text-align: left;
    }
  }

  .mid-col {
    text-align: left;

    img {
      display: inline-block;
      max-width: 145px;
      margin-top: 0;
    }

    @media (min-width: $xl-break) {
      text-align: center;

      img {
        display: inline-block;
        max-width: 220px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .right-col {
    display: none;
    align-items: center;
    justify-content: center;

    padding-top: 2rem;
    padding-bottom: 2rem;

    text-align: center;

    @media (min-width: $xl-break) {
      display: flex;
      text-align: right;
      justify-content: flex-end;
    }

    a {
      display: inline-block;
      margin-left: 2rem;
      font-weight: 600;

      svg {
        fill: $colorAccent3;
      }
    }
  }
}

.main-header {
  width: 100%;
  z-index: 50;
  background: #fff;
  font-weight: 600;
  border-top: 1px solid rgba(#303030, 0.2);
  border-bottom: 1px solid rgba(#303030, 0.2);

  padding: 1rem 1rem;

  @media (min-width: $xl-break) {
    padding: 0;
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;

    background: #fff;
    border: none;

    .header-logo {
      img {
        height: 30px;

        @media (max-width: 1179px) {
          height: 20px;
        }
      }
    }

    &.hide-nav-bar {
        // -webkit-transform: translate(0, -100%);
        // -ms-transform: translate(0, -100%);
        // transform: translate(0, -100%);
        // -webkit-backface-visibility: hidden;
    }
  }

  .header-container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .header-lang {
    display: inline-block;
    padding: 0;
    min-width: 275px;
    font-weight: 600;
    color: $dark;
    text-transform: uppercase;

    @media (min-width: $xl-break) {
      padding: 0 3.5rem;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1179px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-size: 1.15rem;
      line-height: 1;

      text-transform: uppercase;
      font-weight: 600;
      color: $dark;
      margin: 0 14px;

      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent1;
      }

      @media (max-width: 1400px) {
        position: relative;
        left: -25px;
        font-size: 13px;
        margin: 0 8px;
      }

      &.is-active:after {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        height: 7px;
        background-color: #E60000;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .site-btn {
      padding: 1.75rem 3.5rem;

      @media (max-width: 1400px) {
        font-size: 1.5rem;
      }

      @media (max-width: 1179px) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1179px) {
        display: block;
      }
    }
  }
}


// --------------------------------------------------
// Mobile Version
// --------------------------------------------------

// Some global reset needed...
.site-container {
  transition: all .3s ease-in-out;
}

.site-container.active {

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0,0,0,.65);
    opacity: 0;
  }

  @media all and (max-width: 1140px) {
    transform: translateX(-400px);

    &:before {
      z-index: 15;
      opacity: 1;
    }
  }

  @media all and (max-width: 499px) {
    transform: translateX(-85%);
  }
}

.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: #F0F0F0;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .logo {
      height: 22px;
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(#000, 0.075);

    a {
      //@extend .link;
      margin-bottom: 10px;
      color: $dark;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      background-color: #303030;
      padding: 15px;
      border-radius:3px;
      color: #fff;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 15px;
    border-bottom: 1px solid rgba(#000, 0.075);

    a {
      //@extend .link;
      font-size: 14px;
      margin-bottom: 35px;
      color: $dark;
      text-transform: uppercase;
      text-align: center;

      &.site-btn {
        color: white;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    a {
      //@extend .link;
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    svg {
      margin-right: 20px;
      height: 24px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
