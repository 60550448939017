// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn,
[type="submit"] {
  display: inline-block;
  width: 100%;
  padding: 1.35rem 2rem 1.35rem 2rem;
  border: 2px solid $colorAccent1;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontTextLight;
  font-size: 1.275rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1rem;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  border-radius: 0;
  text-transform: uppercase;

  &:hover {
    opacity: .75;
  }

  @media (min-width: $sm-break) {
    font-size: 1.25rem;
    padding: 1.75rem 2.5rem 1.75rem 2.5rem;
  }

  @media (min-width: $lg-break) {
    width: auto;
  }

  &.is-outlined {
    padding: 15px 25px;
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    border-color: $colorAccent3;
    background: $colorAccent3;
  }
}

.site-link-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccent1;
}
