

//Footer
.site-footer {
  // display: flex;
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $colorAccent3;
  letter-spacing: 0;
  border-top: 1px solid rgba(48,48,48,0.2);

  p {
    margin-top: 25px;
    letter-spacing: 1px;
  }

  img {
    width: 200px;
    margin-bottom: 3vw;

    @media (min-width: $md-break) {
      width: 500px;
    }
  }
}
