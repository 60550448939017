.css-grid-unsupported {
  // Grid
  [#{$prefix}~='grid'] {
    display: flex !important;
    justify-content: space-between !important;
    align-items: stretch !important;
  }

  .main-header-top {
    [#{$prefix}~='grid'] {
      align-items: center !important;
    }
  }

  @media (min-width: $lg-break) {
    [bp~='6@lg'] {
      width: 48%;
    }
  }

  [#{$prefix}~='4'] {
    width: 32%;
  }

  .services-listing {
    [#{$prefix}~='grid'] {
      flex-wrap: wrap;
    }

    [bp~='4@lg'] {
      width: 32%;
    }
  }


  .argument-item {
    width: 100% !important;
  }

  .hero .hero-form .form-wrap {
    margin: 0;
  }

  .split-text-img-intro .split-content-text,
  .split-text-img-content .split-content-text {
    padding: 13rem !important;
  }

  .split-text-img-intro {
    [#{$prefix}~='grid'] {
      flex-wrap: wrap !important;
    }

    .split-text-img-title,
    .regular-text {
      width: 100% !important;
    }
  }

  .properties-listing,
  .articles-listing {
    [#{$prefix}~='grid'] {
      flex-wrap: wrap;
    }

    [bp~='4@lg'] {
      width: 32%;
    }
  }
}
