html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

[#{$prefix}~='container'] {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: $container-width;

  &.no-padding {
    padding: 0;
  }
}

[#{$prefix}~='container-fluid'] {
  position: relative;
  width: 100%;
  padding: 3.5rem $site-gutter-mobile;

  @media (min-width: $xl-break) {
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: $site-gutter;
    padding-right: $site-gutter;
  }

  @media (min-width: $xxl-break) {
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: $site-gutter-large;
    padding-right: $site-gutter-large;
  }

  &.no-padding {
    padding: 0;
  }
}

[#{$prefix}~='container-fluid'].is-smaller {
  position: relative;
  width: 100%;
  padding: 2.5rem $site-gutter-mobile;

  @media (min-width: $xl-break) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: $site-gutter;
    padding-right: $site-gutter;
  }

  @media (min-width: $xxl-break) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: $site-gutter-large;
    padding-right: $site-gutter-large;
  }

  &.no-padding {
    padding: 0;
  }
}

.container-fluid {
  position: relative;
  width: 100%;
  padding: 5rem $site-gutter-mobile;

  @media (min-width: $md-break) {

    padding-left: $site-gutter;
    padding-right: $site-gutter;
  }

  &.no-padding {
    padding: 0;
  }
}

.inner-padding {
  > div {
    padding: 5rem $site-gutter-mobile;

    @media (min-width: $md-break) {

      padding-left: $site-gutter;
      padding-right: $site-gutter;
    }
  }
}
