
// --------------------------------------------------
// Properties Listing page
// --------------------------------------------------
.properties-listing {
  .article-card .article-card-title,
  .article-card .article-price {
    font-family: $fontTextLight;
    font-weight: 500;
    font-size: 1.55rem;
    line-height: 1.3;
    text-align: center;
    color: $colorAccent3;
    border:0;
    margin-bottom: 10px;

    @media (min-width: 461px) {
      font-size: 1.85rem;
    }

    @media (min-width: 900px) {
      font-size: 2rem;
    }

  }

  .article-card .article-price {
    margin: 0;
    padding: 0 0 10px 0;
    font-family: $fontBold;
    font-weight: 900;

    strong {
      font-family: $fontBold;
      font-weight: 900;
      font-size: 115%;
    }
  }

  .articles-icons {
    display: flex;
    margin: 0;
    padding: 15px 0;
    list-style-type: none;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 15px;
      font-size: 1.5rem;
      font-weight: 500;
      color: #303030;

      span {
        margin: 0 10px;
      }
    }
  }

  .article-address {
    padding-bottom: 25px;
  }
}

// --------------------------------------------------
// Properties Details page
// --------------------------------------------------
.property-details {

  [bp~='container-fluid'] {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (min-width: $xl-break) {
      padding-top: 5rem;
      padding-bottom: 2.5rem;
      padding-left: $site-gutter;
      padding-right: $site-gutter;
    }

    @media (min-width: $xxl-break) {
      padding-left: $site-gutter-large;
      padding-right: $site-gutter-large;
    }
  }

  .property-hero {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-size: cover;
    background-position: center;

    @media (min-width: $xl-break) {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.60);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
      color: #fff;
      text-align: center;
    }

    img {
      max-width: 80px;
    }

    p {
      margin: 0;
      padding: 0 0 2.5rem 0;
      font-family: $fontText;
      font-weight: 500;
    }

    h1 {
      font-family: $fontText;
      font-weight: 300;
      font-size: 1.55rem;

      @media (min-width: $xl-break) {
        font-size: 2.5rem;
      }

      strong {
        display: block;
        padding-top: 1rem;
        font-weight: 800;
        font-family: $fontBlack;
        font-size: 2.5rem;

        @media (min-width: $xl-break) {
          font-size: 4.5rem;
        }
      }
    }

    .tag-hero {
      background-color: #E60000;
      padding: 8px 12px;
      border-radius: 3px;
      font-style: normal;
      font-weight: 800;
      font-size: 1.35rem;
      line-height: 1;
      letter-spacing: .05rem;
      color: #fff;
      text-align: center;
    }
  }

  .property-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: $colorAccent3;
    color: #fff;
    padding: 0 0 15px 0;

    @media (min-width: $xl-break) {
      padding: 0 0 0 0;
    }

    a {
      display: inline-block;
      padding: 15px 12px 0 12px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;

      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent1;
      }

      @media (min-width: $xl-break) {
        padding: 20px;
        font-size: 1.35rem;
      }
    }
  }

  .property-section-title {
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.1;
    text-align: center;
    color: #303030;

    @media (min-width: $xl-break) {
      font-size: 2.5rem;
    }
  }

  .property-section-subtitle {
    margin-top: 3.5rem;
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.35rem;
    line-height: 1.1;
    text-align: center;
    color: #303030;

    @media (min-width: $xl-break) {
      font-size: 1.45rem;
    }
  }

  .regular-text {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.15rem;

    @media (min-width: $xl-break) {
      font-size: 1.25rem;
    }
  }

  .stats-icons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    padding: 15px 0;
    list-style-type: none;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px;
      font-size: 1.25rem;
      font-weight: 500;
      color: #2B2A28;

      span {
        margin: 0 10px;
      }
    }
  }

  .infos-listing {
    .regular-text {
      max-width: none;
      margin: 0;
      text-align: left;
      border-top: 1px solid #EAEAEA;
      padding-top: 1rem;

    }
  }

  .table {
    width: 95%;

    @media (min-width: $xl-break) {
      width: 100%;
    }

    .row,
    .table-headings {
      margin: 0;
      padding: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      line-height: 1;
    }

    .table-headings {
      strong {
        display: inline-block;
      }
    }

    .regular-text {
      font-size: 1.15rem;

      @media (min-width: $xl-break) {
        font-size: 1.25rem;
      }

      &:last-child {
        border-bottom: 1px solid #EAEAEA;
      }
    }
  }

  .rtable {
    width: 200%;
    max-width: 200%;
    padding-right: 20px;
    text-align: left;
    line-height: 1;

    @media (min-width: $xl-break) {
      width: 100%;
      max-width: 100%;
    }

    tr {
      padding: 1.3rem 0;
      padding-right: 4rem;
      border-top: 1px solid #EAEAEA;

      &:last-child {
        border-bottom: 1px solid #EAEAEA;

        td {
          border-bottom: 1px solid #EAEAEA;
        }
      }
    }

    th {
      font-weight: 800;
    }

    td, th {
      padding: 1.3rem 0;
      padding-right: 4rem;
      border-top: 1px solid #EAEAEA;
      font-size: 1.15rem;

      @media (min-width: $xl-break) {
        font-size: 1.25rem;
      }

      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }
  }

  .full-slider {
    margin: 0;

    .carousel-cell {
      width: 100%;
      overflow: hidden;
    }

    .container img {
      display: block;
      width: 100%;
    }

    .container article {

    }

    .container article:before {
      display: none;
    }

    .flickity-prev-next-button.previous {
      left: 20px;
      background: #fff;

      transform: scale(0.75);

      @media (min-width: $xl-break) {
        transform: none;
      }

      svg {
        transform: scale(0.75);
      }

      svg path {
        fill: $colorAccent1;
      }
    }

    .flickity-prev-next-button.next {
      right: 20px;
      background: #fff;

      transform: scale(0.75);

      @media (min-width: $xl-break) {
        transform: none;
      }

      svg {
        transform: scale(0.75);
      }

      svg path {
        fill: $colorAccent1;
      }
    }
  }

  .property-carte {
    padding-bottom: 5rem;
    img {
      display: block;
      width: 100%;
    }
  }

  .property-form {
    background: $colorAccent3;
    color: #fff;
    text-align: center;

    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (min-width: $xl-break) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    .medium-title {
      color: #fff;

      br {
        display: none;

        @media (min-width: $xl-break) {
          display: block;
        }
      }
    }

    form {
      max-width: 470px;
      margin: 0 auto;

      ::-webkit-input-placeholder { /* Edge */
        color: #fff;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #fff;
      }

      ::placeholder {
        color: #fff;
      }

      input[type="text"],
      input[type="email"] {
        background: rgba(234, 234, 234, 0.2);
        color: #fff;
      }

      select {
        background-image: url(/img/site/arrow-down-2.svg);
        background-position: calc(100% - 15px) center;
        background-color: rgba(234, 234, 234, 0.2);
        background-repeat: no-repeat;
        color: #fff
      }
    }
  }
}

.mobile-tableScroller-wrapper {
  overflow-x: scroll;
  margin-right: -15px;
  width: auto;

  @media (min-width: $lg-break) {
    overflow-x: hidden;
    margin-right: auto;
    width: 100%;
  }

  .mobile-tableScroller {
    width: auto;

    @media (min-width: $lg-break) {
      width: 100%;
    }
  }
}

.contact-map {
  .regular-text {
    font-size: 1.25rem;
    line-height: 1.55;

    @media (min-width: 461px) {
      font-size: 1.45rem;
      line-height: 2;
    }
  }

  figcaption {
    flex-direction: column;
    text-align: center;

    @media (min-width: 461px) {
      flex-direction: row;
      text-align: left;
    }
  }
}

.tag {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-block;
  padding: 8px 12px;
  background: #E60000;
  border-radius: 3px;
  font-style: normal;
  font-weight: 800;
  font-size: 1.35rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  color: #FFFFFF;
  text-align: center;

  @media (min-width: $xl-break) {
    top: 25px;
    left: 25px;
  }

  &.relative {
    position: relative;
    top: 0;
    left: 0;
  }
}
