.firstpage {
  .left-col,
  .right-col {
    opacity: 0;
    filter: blur(10px);

    transition: opacity 0.75s cubic-bezier(.455, .03, .515, .955) 2s, filter 1.55s cubic-bezier(.455, .03, .515, .955) 2s;
    will-change: opacity;
    backface-visibility: hidden;
  }

  &.is-loaded {
    .left-col,
    .right-col {
      opacity: 1;
      filter: none;
    }
  }

  // .main-header-top .mid-col img {
  //   max-width: 250px;

  //   transition: max-width 2.5s cubic-bezier(.455, .03, .515, .955) 1s;
  //   will-change: max-width;
  //   backface-visibility: hidden;
  // }

  // &.is-loaded {
  //   .main-header-top .mid-col img {
  //     max-width: 160px;
  //   }
  // }
}

.hero-content {
  opacity: 0;
  filter: blur(10px);

  transition: opacity 0.75s cubic-bezier(.455, .03, .515, .955) 0.45s, filter 1.55s cubic-bezier(.455, .03, .515, .955) 0s;
  will-change: opacity;
  backface-visibility: hidden;
}

.is-loaded {
  .hero-content {
    opacity: 1;
    filter: none;
  }
}

.hero-visual.desktop {
  // transform: scale(1.1235123);
  // transition: transform 13s linear 1s;
  // will-change: transform;
  // backface-visibility: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $colorAccent3;
    z-index: 1;

    transform: translate3d(0, 0, 0);
    transition: transform 1s cubic-bezier(.455, .03, .515, .955) 0.50s;
    will-change: transform;
    backface-visibility: hidden;
  }
}

.is-loaded {
  .hero-visual.desktop {
    // transform: scale(1);

    &:after {
      transform: translate3d(100%, 0, 0);
    }
  }
}
