* {
  font-family: $fontRegular;
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontTitle;
}

// --------------------------------------------------
// Titles
// --------------------------------------------------
.big-title {
  font-size: 1.85rem;
  color: $colorAccent1;
  font-weight: 700;

  @media (min-width: 461px) {
    font-size: 2rem;
  }

  @media (min-width: $sm-break) {
    font-size: 3.1rem;
  }

  @media (min-width: $xxxl-break) {
    font-size: 3.75rem;
  }
}

.medium-title {
  font: $fontBold;
  font-size: 1.85rem;
  color: $colorAccent3;
  font-weight: 600;

  @media (min-width: $sm-break) {
    font-size: 2.65rem;
  }

  @media (min-width: $xxxl-break) {
    font-size: 2.85rem;
  }
}

.regular-title {
  font: $fontBold;
  font-size: 1.45rem;
  font-weight: 600;
  letter-spacing: 0;

  @media (min-width: $xxxl-break) {
    font-size: 2rem;
  }
}

.small-title {
  font-size: 1.45rem;
  font-weight: 600;
  line-height: 1.5;

  @media (min-width: $xxxl-break) {
    font-size: 2rem;
  }
}

.regular-text {
  font-size: 1.45rem;
  line-height: 2;
  letter-spacing: 0;
  font-weight: 400;

  @media (min-width: $xxxl-break) {
    font-size: 1.55rem;
  }
}

.regular-text.is-bigger {
  font-size: 1.5rem;
  line-height: 2;
  letter-spacing: 0;
  font-weight: 400;

  @media (min-width: $xxxl-break) {
    font-size: 1.85rem;
  }
}

.uppercase {
  text-transform: uppercase;
}
