.form {
  width: 100%;

  input[type="text"],input[type="email"],select {
    width: 100%;
    height: 50px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 14px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 5px;
    background-color: #fff;
    color: $colorTextDark;
    font-family: $fontText !important;

    &::placeholder {
      color: $colorTextDark;
    }
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 150px;
    max-height: 150px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 14px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 5px;
    background-color: #fff;
    color: $colorTextDark;
    font-family: $fontText !important;

    &::placeholder {
      color: $colorTextDark;
    }
  }

  select {
    background-image: url(/img/site/arrow-down.svg);
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    padding-right: 40px;
  }

  input[type="submit"] {
    border:0;
    margin-top: 20px;
  }
}


.form-message {
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  line-height: 1;
  margin: 0 0 20px 0;
  color: green;

  &.error {
    color: red;
  }
}
