// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontBold;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a {
    width: 30px;
    height: 30px;
    padding: 10px;
    line-height: 1;
  }

  svg {
    fill: $colorAccent3;

    path {
      fill: $colorAccent3;
    }
  }

  a,
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background: #fff;
    color: $colorAccent3;
    text-decoration: none;

    &.content-pagination-prev,
    &.content-pagination-next {
      width: 45px;
      // height: 45px;
      // padding: 10px 15px;

      i {
        color: white;
        pointer-events: none;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;

      }
    }

    &.content-pagination-prev {
      margin-right: 25px;

      &.is-disabled {
      }

      span {
        // border-right: 6.2px solid $colorAccent1;
         .icon-right,
         .icon-left {
           color: $white;
         }

        &.is-disabled {
          // border-right-color: black;
        }
      }
    }

    &.content-pagination-next {
      margin-left: 25px;

      span {
        // border-left: 6.2px solid white;
        .icon-right,
        .icon-left {
          color: $white;
        }

        &.is-disabled {
          // border-left-color: black;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      width: 31px;
      height: 31px;
      padding: 0;
      background: $colorAccent3;
      color: #fff;
      text-indent: 0.15rem;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.loader-container {
  position: relative;
  width: 100%;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
