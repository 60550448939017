// --------------------------------------------------
// Hero
// --------------------------------------------------
.hero {
  position: relative;
  background: #fff;
  overflow: hidden;

  @media (min-width: $md-break) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  // Hero with Visual variant
  &.has-image {
    @media (max-width: 959px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .hero-content {
    padding: 0;

    @media (min-width: $md-break) {
      padding: 7rem 0;
    }

    .hero-title {
      max-width: none;
      margin: 0;
      padding: 0;
      padding-right: 6vw;
      line-height: 1.2;
      letter-spacing: 0.02em;
      color: $colorAccent3;

    }
  }

  .hero-author,
  .hero-text-wrapper,
  .hero-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-direction: column;

    @media (min-width: $lg-break) {
      flex-direction: row;
      padding: 3rem 4rem 4.5rem 0;
    }

    padding: 3rem 0 0 0;
    max-width: none;
    padding-right: 6vw;

    img {
      display: block;
      border-radius: 50%;
      max-width: 75px;
      margin-right: 1.5rem;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .hero-author-text,
    .hero-text,
    .hero-list-item {
      margin: 0;
      color: $colorAccent3;
      font-size: 1.15rem;
      font-weight: 400;
      line-height: 1.75;

      p {
        margin: 0;
        padding: 0 0 0.5rem 0;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75;
        letter-spacing: 0.05rem;

        @media (min-width: 461px) {
          font-size: 1.45rem;
        }
      }

      strong {
        color: $colorAccent3;
        margin: 0;
        padding: 0 0 0 0;
        line-height: 1;
        font-style: italic;
      }
    }

    .hero-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      transition: .3s;
      transition-duration: .3s;

      &:hover {
        color: $colorAccent1;
      }

      svg {
        margin-right: 2rem;
      }

      &.item-phone {
        svg {
          margin-right: 3.25rem;
        }
      }
    }
  }

  .hero-visual.desktop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;

    background: rgba(#fff, 0.80);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    overflow: hidden;

    @media (max-width: 959px) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      background-image: url(http://emmanuelle-beaudet.test/files/hero/_1024x768_crop_center-center_100_none/hero-home-2.jpg);
      height: 400px;
      background-size: cover;
      // Avoid Grid Gap
      margin-bottom: 40px;
      margin-top: -60px;
      margin-left: -80px;
      margin-right: -80px;
      width: auto;
      background-position: center -65px;
    }

    @media (max-width: 599px) {
      // Avoid Grid Gap
      height: 300px;
      margin-top: -40px;
      margin-left: -30px;
      margin-right: -30px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -55%, 0) scale(1.175);
      display: block;
      width: auto;
      height: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $light;
      z-index: 1;

      transform: translate3d(0, 0, 0);
      transition: transform 1s cubic-bezier(.455, .03, .515, .955) 0.50s;
      will-change: transform;
      backface-visibility: hidden;
    }

    &.is-loaded {
      &:after {
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  .hero-visual.mobile {
    img {
      display: none;
      width: 100%;
    }
  }

  .hero-form {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F4F0;
    // Avoid Grid Gap
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;

    @media (min-width: $md-break) {
      // Avoid Grid Gap
      margin-top: -45px;
      margin-left: -60px;
      margin-right: -60px;
      margin-bottom: -60px;
      padding-bottom: 60px;
    }

    @media (min-width: $lg-break) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      padding: 40px;
      margin: 0;
    }

    .form-wrap {
      position: relative;
      z-index: 2;
      max-width: 470px;
      margin: 0 auto;
      text-align: center;
    }

    .form-title {
      margin-top: 5rem;
      font-style: normal;
      font-weight: bold;
      font-size: 2.25rem;
      line-height: 1.2;
      color: $colorAccent3;

      @media (min-width: $lg-break) {
        margin-top: 0;
      }
    }
  }
}

// --------------------------------------------------
// Arguments Row
// --------------------------------------------------
.arguments-row {
  padding: 0;

  .argument-item {
    padding: 1vw;
    background: $colorAccent4;

    &:nth-child(even) {
      background-color: #F7F4F0;
    }

    @media (min-width: $md-break) {
      padding: 1vw;
    }
  }

  .argument-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 461px) {
      justify-content: center;
    }

    .argument-item-main,
    .argument-item-second {
      width: 35%;
      text-align: right;

      @media (min-width: 461px) {
        width: auto;
      }
    }

    .argument-item-second {
      text-align: left;
      width: 100%;

      @media (min-width: 461px) {
        width: auto;
      }
    }

    .argument-item-main {
      font-family: $fontBold;
      margin-right: 1rem;
      font-size: 3rem;
      font-weight: 800;

      @media (min-width: $md-break) {
        font-size: 3rem;
      }

      small {
        font-size: 2.15rem;
        font-weight: 700;
      }
    }

    .argument-item-second {
      font-weight: 400;
      font-weight: 500;
      letter-spacing: 0.5px;
      max-width: 180px;
      font-size: 1.25rem;

      @media (min-width: $md-break) {
        font-size: 1.35rem;
      }
    }
  }
}

// --------------------------------------------------
// Articles Listing
// --------------------------------------------------
.blog {
  .articles-listing {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.articles-listing {

  padding-bottom: 25px;

  @media (min-width: $xl-break) {
    padding-bottom: 25px;
  }

  .medium-title {
    max-width: 55rem;
    margin: 0 auto;
    padding-bottom: 2rem;

    @media (min-width: 461px) {
      padding-bottom: 4rem;
    }
  }
}

// --------------------------------------------------
// Article Card
// --------------------------------------------------
.article-card {
  position: relative;
  z-index: 2;
  background-color: $alternateLight;

  img {
    display: block;
    width: 100%;
  }

  .article-card-content {
    padding-right: 2rem;
    padding-left: 2rem;
    background: #fff;
    border: 1px solid rgba(48,48,48,.2);
  }

  .article-card-title {
    font-family: $fontText;
    font-weight: 600;
    padding: 2rem;
    margin: 0;
    background-color: #fff;
    border: 1px solid rgba(48,48,48,.2);
    color: #303030;
    letter-spacing: 0;

    font-size: 1.55rem;

    @media (min-width: 461px) {
      font-size: 2rem;
    }

    @media (min-width: $xxxl-break) {
      font-size: 2.5rem;
    }
  }

  .article-card-content {
    .article-card-title {
      font-size: 1.35rem;
      padding: 3rem 0 0 0;
      margin: 0;
      background: none;
      color: $colorAccent3;

      @media (min-width: $sm-break) {
        font-size: 1.55rem;
      }

      @media (min-width: $md-break) {
        font-size: 1.75rem;
      }
    }
  }

  .article-text {
    margin: 0 auto;
    padding: 3rem 0;

    font-size: 1.25rem;
    line-height: 1.65;

    @media (min-width: 461px) {
      font-size: 1.45rem;
      line-height: 2;
    }
  }

  .site-btn {
    margin-bottom: 2rem;

    @media (min-width: 461px) {
      margin-bottom: 5rem;
    }
  }
}

.articles-listing.alternate {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: $dark;

  @media (min-width: $md-break) {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .article-card {
    background-color: #fff;

    .article-card-content {
      background-color: #fff;
    }

    img {
      display: block;
      width: 100%;
    }

    .site-btn {
      background: #303030;
      border-color: #303030;
    }
  }
}

// --------------------------------------------------
// Team Card
// --------------------------------------------------
.team-card {
  position: relative;
  z-index: 2;

  img {
    display: block;
    width: 100%;
  }

  .team-card-content {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .medium-title {
    margin: 0;
    padding: 3.5rem 0 0 0;
    color: $colorAccent3;
    font-size: 1.85rem;

    @media (min-width: $md-break) {
      font-size: 2.55rem;
    }
  }

  .small-title {
    margin: 0;
    padding: 0 0 0 0;
    color: $colorAccent5;
  }

  .team-links {
    margin: 0;
    padding: 3rem 0 0 0;
    list-style-type: none;
    color: $colorAccent1;

    .item-phone {
      font-size: 1.75rem;
      line-height: 1.1;
      font-weight: 900;
      color: #E60000;
    }

    .item-email {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.8;
      color: #E60000;

      @media (min-width: $md-break) {
        font-size: 1.5rem;
      }
    }
  }
}

// --------------------------------------------------
// Full Slider
// --------------------------------------------------
.full-slider {
  position: relative;

  @media (min-width: $lg-break) {
    margin-top: -10rem;
  }

  .container {
    position: relative;

    article {
      display: block;
      width: 100%;
      min-height: 400px;
      height: 40vw;
      max-height: 600px;
      background: $dark;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .45);
        z-index: 1;
      }

      .carousel-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        padding-left: 15px;
        padding-right: 15px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: #fff;
        text-align: center;

        .regular-title {
          font-family: $fontText;
          margin: 0;
          padding: 0;

          font-family: $fontText;
          font-size: 1.5rem;
          font-weight: 600;
          letter-spacing: 0;

          @media (min-width: $md-break) {
            font-size: 2.25rem;
          }
        }

        .big-title {
          font-family: $fontText;

          margin: 0;
          padding: 2rem 0;

          color: #fff;
          font-size: 3.25rem;
          font-weight: 800;

          strong {
            font-family: $fontText;
            font-weight: 800 ;
          }

          @media (min-width: $md-break) {
            font-size: 5rem;
          }
        }

        .site-btn {
          width: auto;
        }
      }
    }
  }

  .block-cta {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 1.5rem 2rem;
    margin: 0 auto;
    background-color: $dark;
    text-align: center;

    color: #fff;

    h3.regular-title {
      font-size: 1.8rem;
    }

    @media (min-width: $md-break) {
      text-align: left;
      padding: 2rem 5rem;
      h3.regular-title {
        font-size: 2rem;
      }
    }

    @media (min-width: $lg-break) {
      position: absolute;
      flex-direction: row;
      transform: translate3d(0, 50%, 0);
      left: 10rem;
      right: 10rem;
      h3.regular-title {
        font-size: 2rem;
      }
    }
  }
}

// --------------------------------------------------
// Split
// --------------------------------------------------
.split {
  position: relative;
  margin-bottom: 0;

  @media (min-width: 461px) {
    margin-bottom: 5rem;
  }

  img {
    display: block;
    width: 100%;
  }

  .split-content {
    position: relative;
    z-index: 2;
  }

  .split-content-text {
    // padding-top: 3rem;

    @media (min-width: $md-break) {
      padding-left: 5rem;
    }
  }

  .split-title {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-top: 0;
  }

  .split-small-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .regular-text {
    margin-bottom: 3rem;
  }

  .links {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $colorAccent1;

    .item-phone {
      font-size: 2rem;
      line-height: 1.1;
      font-weight: 900;
      color: #E60000;

      @media (min-width: $md-break) {
        font-size: 3rem;
      }
    }

    .item-email {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.8;
      color: #E60000;

      @media (min-width: $md-break) {
        font-size: 1.5rem;
      }
    }
  }

  .bg-color {
    position: absolute;
    top: 25%;
    right: 55%;
    bottom: 0;
    left: -50%;
    background: $light;

    @media (max-width: 959px) {
      display: none;
    }
  }

  .circle-type {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;

    width: 145px;
    transform: translate3d(-50%, -50%, 0);

    -webkit-animation: slowRotate 100s infinite linear;
    animation: slowRotate 100s infinite linear;

    @media (min-width: $lg-break) {
      display: block;
    }
  }

  &.is-reverse {
    .split-visual {
      @media (min-width: $md-break) {
        order: 2;
      }
    }

    .split-content {
      @media (min-width: $md-break) {
        order: 1;
      }
    }

    .bg-color {
      right: -50%;
      left: 55%;
    }

    .split-content-text {
      // padding-top: 3rem;

      @media (min-width: $md-break) {
        padding-left: 0;
        padding-right: 5rem;
      }
    }

    .circle-type {
      left: auto;
      right: -15rem;
    }
  }
}

// --------------------------------------------------
// Services Listing
// --------------------------------------------------
.services-listing {

  .medium-title {
    max-width: 72rem;
    margin: 0 auto;
    padding-bottom: 5rem;
  }

  .service-card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.55rem;
    line-height: 1.2;
    color: $colorAccent3;
    margin-top: 1rem;
  }

  .service-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.35rem;
    line-height: 1.8;
    color: #2B2A28;
  }
}

// --------------------------------------------------
// Testimonials Slider
// --------------------------------------------------
.testimonials-slider {
  background: $light;

  .carousel-cell {
    width: 100%;
  }

  .icon {
    margin-bottom: 2rem;
    width: 44px;

    @media (min-width: 461px) {
      width: 74px;
      margin-bottom: 5rem;
    }
  }

  blockquote {
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
  }

  .regular-text {
    max-width: 875px;
    margin: 0 auto;

    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.8;

    color: #2b2a28;

    @media (min-width: $md-break) {
      font-size: 1.75rem;
    }

    strong {
      display: block;
      margin-top: 2rem;
    }
  }

  .cite {
    margin: 0;
    padding: 0;
  }

  .flickity-page-dots {
    .dot {
      width: 15px;
      height: 15px;
      border: 2px solid #2b2a28;
      background: transparent;

      &.is-selected {
        background: $colorAccent1;
      }
    }
  }
}

// --------------------------------------------------
// Call to action
// --------------------------------------------------
.cta {
  background: $colorAccent3;
  padding: 70px 40px;

  @media (min-width: $lg-break) {
    padding: 8.5rem 6vw;
  }

  &.is-dark {
    background: $dark;
  }

  .medium-title {
    max-width: 1024px;
    margin: 0 auto 2rem auto;
    color: $white;
    font-size: 1.85rem;

    @media (min-width: 461px) {
      font-size: 3rem;
    }

    br {
      display: none;
    }

    @media (min-width: $lg-break) {
      br {
        display: block;
      }
    }
  }
}

// --------------------------------------------------
// Split text img
// --------------------------------------------------
.split-text-img-content {
  padding: 0;
}

.split-text-img-intro,
.split-text-img-content {

  &.split-text-img-content {
    background: #fff;
  }

  .regular-text {
    max-width: 775px;
    margin: 0 auto;
  }

  //

  .split-content {
    position: relative;
    z-index: 2;
  }

  .split-visual {
    position: relative;
    width: auto;
    background: $light;
    padding: 40px 40px 0 40px;

    @media (min-width: $xl-break) {
      margin-top: 10rem;
      background: none;
      padding: 0;
    }

    img {
      display: block;
      width: auto;
      max-width: 80%;
      margin: 0 auto;

      @media (min-width: $xl-break) {
        width: auto;
        max-height: 540px;
        margin-left: 5.5rem;
      }
    }
  }

  .split-content-text {
    padding: 0 20px 40px 20px;

    @media (min-width: $xl-break) {
      padding: 40px 40px;
    }

    @media (min-width: $xl-break) {
      padding: 20px 40px 20px 5.5rem;
    }

    .split-title {
      color: $colorAccent3;
    }

    .regular-text {
      max-width: inherit;
      color: $colorAccent3;
    }
  }

  .img-bg-effect {
    display: none;
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;

    background: $light;

    @media (min-width: $xl-break) {
      display: block;
    }
  }

  // Reverse Mode
  &.is-reverse {
    .split-content-text {
      @media (min-width: $xl-break) {
        order: -1;
      }
    }

    .img-bg-effect {
      right: 0;
      left: 50%;
    }

    .split-visual {
      img {
        margin: 0 auto;

        @media (min-width: $xl-break) {
          margin-left: 5.5rem;
        }
      }
    }

    .split-content-text {
      order: 1;

      @media (min-width: $md-break) {
        padding: 20px 5.5rem 20px 40px;
        order: -1;
      }
    }
  }
}

.split-text-img-intro.remove-margins {
  .medium-title {
    margin: 0;
  }
}

.contact-map {
  figure,
  figcaption {
    padding: 0;
    margin: 0;
  }

  figcaption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 0;
  }

  img {
    display: block;
    width: 100%;
  }
}

// --------------------------------------------------
// Misc / Effect / Animations
// --------------------------------------------------
.mobile-scroller-wrapper {
  overflow-x: scroll;
  margin-right: -15px;
  width: auto;

  @media (min-width: $lg-break) {
    overflow-x: hidden;
    margin-right: auto;
    width: 100%;
  }

  .mobile-scroller {
    width: 250%;

    @media (min-width: $lg-break) {
      width: 100%;
    }
  }
}

.floating-titles {
  display: none;

  color: #ffffff;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-53%, 2%, 0);
  opacity: .1;

  z-index: 1;
  font-family: $fontTitle;

  strong {
    display: block;
    text-align: left;
    font-size: 3.85rem;
    letter-spacing: 0;
    font-weight: 600;
    font-family: $fontTitle;
  }

  @media (min-width: $lg-break) {
    display: block;
  }
}

@-webkit-keyframes slowRotate {
  from { transform: translate3d(-50%, 50%, 0) rotate(125deg); }
  to   { transform: translate3d(-50%, 50%, 0) rotate(485deg); }
}
@keyframes slowRotate {
  from { transform: translate3d(-50%, 50%, 0) rotate(125deg); }
  to   { transform: translate3d(-50%, 50%, 0) rotate(485deg); }
}

.plans {
  position: relative;

  .plan-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 110%;
    padding-bottom: 35px;

    @media (max-width: 767px) {
      font-size: 28px;
      padding-bottom: 10px;
    }
  }
  .plans-items {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .plan-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style-type: none;
      line-height: 1;
      text-align: left;
      border-top: 1px solid #B3B3B3;
      border-bottom: 1px solid #B3B3B3;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: -1px;

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

       @media (max-width: 767px) {

       }

      .plan-item-address {
        width: 15%;
        min-width: 200px;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        color: #E60000;
      }
      .plan-item-desc {
        width: 100%;
        font-family: $fontRegular;
        font-style: normal;
        font-weight: 200;
        font-size: 16px;
        line-height: 1;
        color: #2B2A28;

      }
      .float-right-item {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @media (max-width: 767px) {
          position: relative;
          top: 0;
          transform: none;
       }
      }
      .btns {
        display: flex;
        align-items: center;
        min-width: 200px;
      }
      .plan-item-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: $fontRegular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        color: #2B2A28;
        margin-left: 30px;
        min-width: max-content;

        svg {
          margin-left: 5px;
        }

        @media (max-width: 767px) {
          margin-left: 0;
          margin-right: 25px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .label {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 1;
        color: #fff;
        background: #E60000;
        padding: 8px 16px;
        padding-top: 10px;
        border-radius: 3px;
        letter-spacing: 0;
      }
    }
  }
}

.split .split-content-text {
  ul {
    padding: 10px 0;
    margin: 0;
    list-style-type: none;

    li {
      position: relative;
      margin: 12px 0;
      padding: 0 0;
      padding-left: 25px;

      font-family: $fontRegular;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 1;
      color: #2B2A28;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 9px;
        height: 9px;
        transform: translate3d(0, -50%, 0);
        background-color: #E60000;
        border-radius: 50%;
        z-index: 1;
      }
    }
  }
}

.spacer {
  height: 6vw;
}
