main {

  .flickity-prev-next-button {
    background: none;
    color: #fff;

    svg,
    svg path {
      fill: #fff;
      color: #fff;
    }

    &.previous {
      left: 0rem;

      @media (min-width: $md-break) {
        left: 10rem;
      }

      &:before {
        color: #fff;
      }

      &:hover {
        background: none;
      }
    }

    &.next {
      right: 0rem;

      @media (min-width: $md-break) {
        right: 10rem;
      }

      &:before {
        color: #fff;
      }

      &:hover {
        background: none;
      }
    }
  }

  .flickity-page-dots {
    bottom: 0;
  }
}

/* .is-fullscreen added to carousel when fullscreen
   size cells to full height when fullscreen */
.main-carousel.is-fullscreen {
  z-index: 99;
}
.main-carousel.is-fullscreen .carousel-cell {
  height: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block;
    width: 125% !important;
  }
}
