
.blog-content-wrapper {
  width: 100%;
  max-width: 840px;
  padding: 90px 20px;
  margin: 0 auto;
  font-family: $fontTitle;

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  .blog-date {
    display: none;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .5px;
    color: $colorAccent1;
    font-weight: 600;
    text-align: center;
    margin: 0 0 40px;
    text-transform: uppercase;
  }

  .blog-content {
    a {
      color: $colorAccent1;
    }

    strong {
      font-family: $fontText;
      font-weight: 700;
    }

    ul {
      list-style: none;
      margin: 0 0 30px;
      padding: 0;

      li {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: .5px;
        color: #757C8B;
        font-weight: 400;

        &:before {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          content: "\E82B";
          margin-right: 20px;
          color: $colorAccent1;

          @media(max-width: 900px) {
            margin-right: 10px;
          }
        }
      }
    }

    figure {
      margin: 0 0 30px;
    }

    img {
      max-width: 100%;
    }

    .small-title {
      color: $colorAccent5;
    }

    .regular-text {
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.8;
      color: $dark;
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
