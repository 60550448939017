// Prefixes
$prefix: 'bp'; // prefix layout attibute

// Grid Variables
$container-width: 1600px;
$gutter: 40px;
$cols: 12;
$site-gutter-large: 6vw;
$site-gutter: 5vw;
$site-gutter-mobile: 6vw;

// Breakpoint Variables
$no-break: 0;
$sm-break: 480px;
$md-break: 720px;
$lg-break: 960px;
$xl-break: 1180px;
$xxl-break: 1440px;
$xxxl-break: 1640px;

// Spacing Variables
$xs-spacing: 4px;
$sm-spacing: 8px;
$md-spacing: 16px;
$lg-spacing: 24px;

// Size Suffixes
$xs-suffix: '--xs';
$sm-suffix: '--sm';
$md-suffix: '';
$lg-suffix: '--lg';
$none-suffix: '--none';

// Location Suffixes
$no-suffix: '';
$top-suffix: '-top';
$bottom-suffix: '-bottom';
$left-suffix: '-left';
$right-suffix: '-right';

// Lists
$location-suffixes: $no-suffix, $top-suffix, $bottom-suffix, $right-suffix, $left-suffix;

// Maps
$spacing-map: ($xs-suffix: $xs-spacing, $sm-suffix: $sm-spacing, $md-suffix: $md-spacing, $lg-suffix: $lg-spacing, $none-suffix: 0);

// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Engel Voelkers Head', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontTitleSerif: 'Engel Voelkers Head', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontText: 'Engel Voelkers Text', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontTextLight: 'Engel Voelkers Text', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

$fontBlack: 'Engel Voelkers Head', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontBold: 'Engel Voelkers Head', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontBold: 'Engel Voelkers Head', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontRegular: 'Engel Voelkers Text', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontLight: 'Engel Voelkers Text', -apple-system, Helvetica, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// Accents Colors
$colorAccent1: #E60000;
$colorAccent2: #ffffff;
$colorAccent3: #303030;
$colorAccent4: #E0D7CF;
$colorAccent5: #F7F4F0;

// Texts
$colorTextDark: #303030;
$colorTextRegular: #303030;
$colorTextLight: #fff;

// Blocks and Text
$dark: #2b2a28;
$grey: #F7F4F0;
$light: #F7F4F0;
$alternateLight: #ffffff;
$lighter: #F7F4F0;
$alternateWhite: #F8F8F8;
$white : #fff;

$colorSuccess: #56C568;
